@media (min-width: 992px) {
  #page-container.sidebar-o {
      padding-left: 0px!important;
  }
}

@media (min-width: 992px) {
  .sidebar-o #page-overlay {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
  }
}
