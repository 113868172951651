.loading-img-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 50px;
  margin: -60px 0 20px -80px;
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
